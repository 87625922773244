<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :color="$props.color"
  >
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn @click="removeProfilePic" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-virtual-scroll
        v-if="uploadedProfilePic"
        :items="[uploadedProfilePic]"
        height="150"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title class="">
                {{ item.name }}
                <span class="ml-3 text--secondary">
                    {{ item.size ? String(item.size) + 'bytes' : '' }}
                  </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click.stop="removeProfilePic" icon>
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-img v-if="profilePicSrc" :src="profilePicSrc" max-width="120px" max-height="120px"></v-img>
        <v-icon v-else :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          mdi-account-circle
        </v-icon>
        <mex-p content="Drop the profile picture here ..."/>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-file-input
        outlined
        label="... or select from explorer"
        accept=".png"
        v-model="explorerUploadedProfilePics"
        @change="explorerProfilePicChanged"
      >
      </v-file-input>
    </v-card-actions>
  </v-card>
</template>

<script>

import MexBtn from "../MedITEX_Vue_Components/MexComponents/MexButton";
export default {
  name: "ProfilePicUpload",
  components: {
    MexBtn
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    profilePicSrc: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      dragover: false,
      uploadedProfilePic: null,
      explorerUploadedProfilePics: null,
    };
  },
  methods: {
    explorerProfilePicChanged() {
      this.uploadedProfilePic = this.explorerUploadedProfilePics;
      this.explorerUploadedProfilePics = null;
      this.profilePicSrc = URL.createObjectURL(this.uploadedProfilePic);
      this.$emit('uploadedProfilePic', this.uploadedProfilePic);
    },
    onDrop(upload) {
      if (upload.dataTransfer.files.length > 1) {
        this.$toast.error('Only one file can be uploaded at a time');
      } else if (upload.dataTransfer.files.length) {
        if (upload.dataTransfer.files[0].name.split('.').pop() !== 'png') {
          this.$toast.error('Only PNG-files can be uploaded here');
          return;
        }
        this.uploadedProfilePic = upload.dataTransfer.files[0];
        this.profilePicSrc = URL.createObjectURL(this.uploadedProfilePic);
        this.$emit('uploadedProfilePic', this.uploadedProfilePic);
      }
    },
    removeProfilePic() {
      this.uploadedProfilePic = null;
      this.explorerUploadedProfilePics = null;
      this.profilePicSrc = null;
      this.$emit('currentProfilePicDeleted');
    },
  }
};
</script>

<style scoped></style>
