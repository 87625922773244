import httpClient from '../httpClient/httpClient';

const SqlStatementsService = {
  createSqlStatement(sqlStatementData) {
    return httpClient.post('api/create-sql-statement', {
      sqlStatementData,
    });
  },
  updateSqlStatement(sqlStatementData) {
    return httpClient.post('api/update-sql-statement', {
      sqlStatementData,
    });
  },
  getSqlStatementNames() {
    return httpClient.get('api/get-sql-statement-names', {});
  },
  getSqlStatement(sqlStatementID) {
    return httpClient.get('api/get-sql-statement', {
      params: {
        sqlStatementID,
      },
    });
  },
  getSqlStatements() {
    return httpClient.get('api/get-sql-statements', {});
  },
  deleteSqlStatement(sqlStatementID) {
    return httpClient.post("api/delete-sql-statement", {
      sqlStatementID
    });
  },
  deleteSqlStatementAssignments(sqlStatementID) {
    return httpClient.post("api/delete-sql-statement-assignments", {
      sqlStatementID
    });
  },
  getSqlBroadcasterConfig(){
    return httpClient.get("api/get-sql-broadcaster-config")
  },
  getSqlStatementResults(statementName, clinicIDs, fileType, downloadType) {
    return httpClient.get('api/get-sql-statement-results', {
      params: {
        statementName,
        clinicIDs,
        fileType,
        downloadType
      },
    });
  },
};

export default SqlStatementsService;
