<template>
  <v-container class="no-padding">
    <v-dialog v-model="showGenericDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          {{ genericDialog.title }}
        </v-card-title>
        <v-card-text class="text-h5">
          {{ genericDialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- call of function with generic name but - if overwritten - specific content,
           for more information see documentation in data-property -->
          <mex-btn
            v-if="genericDialog.okClick"
            content="OK"
            text
            @click="genericDialog.okClick()"
          ></mex-btn>
          <v-spacer v-if="genericDialog.okClick && genericDialog.cancelClick"></v-spacer>
          <!-- call of function with generic name but - if overwritten - specific content,
           for more information see documentation in data-property -->
          <mex-btn
            v-if="genericDialog.cancelClick"
            content="CANCEL"
            text
            @click="genericDialog.cancelClick()"
          ></mex-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <mex-dialog
      v-model="showCreateOrEditClinicContactDialog"
      :dialog-title="showCreateOrEditClinicContactDialogText"
      :show-dialog="showCreateOrEditClinicContactDialog"
      dialogMaxWidth="1100px"
    >
      <template v-slot:dialog-content>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="currentClinicContact.firstName"
                  :rules="[createClinicContactRules.required]"
                  dense
                  label="First Name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="currentClinicContact.lastName"
                  :rules="[createClinicContactRules.required]"
                  dense
                  label="Last Name"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="currentClinicContact.mail"
                  :rules="[createClinicContactRules.email]"
                  dense
                  label="E-Mail"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-virtual-scroll
              :items="currentClinicContact.phoneNumber"
              height="128"
              item-height="59"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item" class="pa-5">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="item.phoneNumber"
                        dense
                        label="Phone-Number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="item.phoneNumberType"
                        :disabled="item === ''"
                        dense
                        label="Phone-Number-Type"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="currentClinicContact.phoneNumber.length > 1" cols="1">
                      <mex-btn
                        icon="mdi-close"
                        icon-only
                        tooltip="Delete this phone-number"
                        @click="removePhoneNumber(item)"
                      ></mex-btn>
                    </v-col>
                  </v-row>
                </v-list-item>
              </template>
            </v-virtual-scroll>
            <v-row>
              <v-col>
                <mex-btn
                  v-if="currentClinicContact.phoneNumber.slice(-1)[0].phoneNumber !== ''"
                  content="Add phone-number"
                  @click="addPhoneNumber"
                ></mex-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="currentClinicContact.note"
                  dense
                  label="(optional) Notes about this contact"
                  name="input-7-1"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <mex-p content="Profile Picture" fontSize="h6" fontWeight="thin" />
            <ProfilePicUpload
              :key="profilePicKey"
              :profilePicSrc="currentClinicContact.profilePicSrc"
              @currentProfilePicDeleted="currentClinicContactProfilePicDeleted"
              @uploadedProfilePic="currentClinicContact.profilePic = $event"
            ></ProfilePicUpload>
          </v-col>
        </v-row>
      </template>
      <template v-slot:dialog-actions>
        <v-row>
          <mex-btn
            content="Cancel"
            icon="mdi-close"
            @click="showCreateOrEditClinicContactCancelFunction"
          ></mex-btn>
          <v-spacer></v-spacer>
          <mex-btn
            :content="showCreateOrEditClinicContactDialogText"
            :disabled="!currentClinicContact.firstName || !currentClinicContact.lastName || (createClinicContactRules.email(currentClinicContact.mail) === 'Invalid E-Mail')"
            icon="mdi-account-plus"
            @click="showCreateOrEditClinicContactButtonFunction"
          >
          </mex-btn>
        </v-row>
      </template>
    </mex-dialog>
    <mex-sheet color="foreground" rounded>
      <v-row>
        <v-col cols="12">
          <mex-p content="Contacts" fontSize="h6" fontWeight="bold-italic" />
          <mex-data-table
            :data="clinicContacts"
            :headers="clinicContactHeaders"
          >
            <template v-slot:item.profilePic="{ item }">
              <v-img :src="item.ProfilePic" max-height="50px" max-width="50px"></v-img>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.Person.lastName + (item.Person.lastName !== "" && item.Person.firstName !== "" ? ", " : "") + item.Person.firstName
              }}
            </template>
            <template v-slot:item.phoneNumbers="{ item }">
              <v-chip-group>
                <v-tooltip
                  v-for="phoneNumber in item.ClinicContactPhoneNumbers"
                  :key="phoneNumber"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" x-small v-on="on">{{ phoneNumber.PhoneNumber }}</v-chip>
                  </template>
                  {{ phoneNumber.PhoneNumberType }}
                </v-tooltip>
              </v-chip-group>
            </template>
            <template v-slot:item.Note="{ item }">
              <mex-btn
                v-if="item.Note"
                icon="mdi-note"
                icon-only
                tooltip="Open note"
                @click="openNoteDialog(item)"
              ></mex-btn>
              <mex-btn v-else disabled icon="mdi-note" icon-only></mex-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <mex-btn
                icon="mdi-pencil"
                iconOnly
                tooltip="Edit clinic contact"
                @click="openEditClinicContact(item)"
              />
              <mex-btn
                icon="mdi-delete"
                iconOnly
                tooltip="Delete clinic contact"
                @click="deleteClinicContact(item)"
              />
            </template>
          </mex-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <mex-btn rounded content="Add contact" @click="openCreateClinicContact"></mex-btn>
      </v-row>
    </mex-sheet>
  </v-container>
</template>

<script>
import ClinicContactsService from "../../services/clinicContacts.service";
import ProfilePicUpload from "./ProfilePicUpload";

export default {
  name: "ClinicContacts",
  components: {
    ProfilePicUpload
  },
  props: {
    clinicId: null
  },
  data() {
    return {
      showGenericDialog: false,
      genericDialog: {
        // function which gets called on click of OK-button of warning dialog: can be overwritten for
        // specific use cases, then afterwards to not mess with other functions it's supposed to be set
        // back to the "genericDialog.defaultOkClick"
        okClick: () => this.showGenericDialog = false,
        // function which gets called on click of CANCEL-button of warning dialog: can be overwritten for
        // specific use cases, then afterwards to not mess with other functions it's supposed to be set
        // back to the "genericDialog.defaultCancelClick"
        cancelClick: () => this.showGenericDialog = false,
        // default function which is used to set-back genericDialog.okClick after being overwritten,
        // IS NOT SUPPOSED TO BE CHANGED
        defaultOkClick: () => this.showGenericDialog = false,
        // default function which is used to set-back genericDialog.cancelClick after being overwritten,
        // IS NOT SUPPOSED TO BE CHANGED
        defaultCancelClick: () => this.showGenericDialog = false,
        text: "",
        title: "Warning"
      },
      clinicContactHeaders: [
        {
          text: "",
          sortable: false,
          value: "profilePic"
        },
        {
          text: "Name",
          sortable: true,
          value: "name"
        },
        {
          text: "E-Mail",
          sortable: true,
          value: "Mail"
        },
        {
          text: "Phone-Number",
          sortable: false,
          value: "phoneNumbers"
        },
        {
          text: "Note",
          sortable: false,
          value: "Note"
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions"
        }
      ],
      clinicContacts: [],
      createClinicContactRules: {
        required: value => !!value || "Required.",
        email: mail => !mail || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail) || "Invalid E-Mail"
      },
      currentClinicContact: {
        firstName: "",
        lastName: "",
        mail: "",
        note: "",
        // if === 0: clinic contact profile pic got explicitly deleted
        // if === 1: clinic contact profile pic stays in the same state as before the operation, no changes
        // if === 'image/png...': clinic contact profile pic got set a new value
        profilePic: 1,
        phoneNumber: [
          {
            phoneNumber: "",
            phoneNumberType: ""
          }
        ],
        profilePicSrc: undefined
      },
      defaultClinicContact: {
        firstName: "",
        lastName: "",
        mail: "",
        note: "",
        // if === 0: clinic contact profile pic got explicitly deleted
        // if === 1: clinic contact profile pic stays in the same state as before the operation, no changes
        // if === 'image/png...': clinic contact profile pic got set a new value
        profilePic: 1,
        phoneNumber: [
          {
            phoneNumber: "",
            phoneNumberType: ""
          }
        ]
      },
      clinicContactEditMode: false,
      phoneNumberNumber: 1,
      showCreateOrEditClinicContactDialog: false,
      // text which is shown as title and in the button --> describes what we're doing with the
      // clinic contact. Options: "Add clinic contact" or "Edit clinic contact"
      showCreateOrEditClinicContactDialogText: "",
      showCreateOrEditClinicContactButtonFunction: () => {
      },
      selectedClinicContact: null,

      // for rerenderings sake
      profilePicKey: 0
    };
  },
  created() {
    ClinicContactsService.getClinicContacts(this.clinicId)
      .then(async (clinicContactResponse) => {
        this.clinicContacts = clinicContactResponse.data.clinicContacts;
        for (let i = 0; i < this.clinicContacts.length; i++) {
          if (this.clinicContacts[i].ProfilePic) {
            try {
              const profilePic = await ClinicContactsService.getClinicContactProfilePic(this.clinicContacts[i].ClinicContactID);
              this.clinicContacts[i].ProfilePic = "data:image/png;base64," + profilePic.data;
            } catch (error) {
              this.$toast.error("Problem while loading profile picture of " + this.clinicContacts[i].Person.firstName + ", " + this.clinicContacts[i].Person.lastName);
            }
          }
        }
      })
      .catch((err) => {
        this.$toast.error("Couldn\'t load clinic contacts: " + err.response.data.message);
      });
  },
  methods: {
    currentClinicContactProfilePicDeleted() {
      !!this.currentClinicContact.profilePic ? this.$toast.success("Successfully deleted profile picture") : this.$toast.info("No profile picture to delete");
      this.currentClinicContact.profilePic = 0;
    },
    showCreateOrEditClinicContactCancelFunction() {
      this.clinicContactEditMode = false;
      this.showCreateOrEditClinicContactDialog = false;
    },
    loadWholeClinicContacts() {
      ClinicContactsService.getClinicContacts(this.clinicId)
        .then(async (clinicContactResponse) => {
          this.clinicContacts = clinicContactResponse.data.clinicContacts;
          for (let i = 0; i < this.clinicContacts.length; i++) {
            if (this.clinicContacts[i].ProfilePic) {
              try {
                const profilePic = await ClinicContactsService.getClinicContactProfilePic(this.clinicContacts[i].ClinicContactID);
                this.clinicContacts[i].ProfilePic = "data:image/png;base64," + profilePic.data;
              } catch (error) {
                this.$toast.error("Problem while loading profile picture of " + this.clinicContacts[i].Person.firstName + ", " + this.clinicContacts[i].Person.lastName);
              }
            }
          }
        })
        .catch((err) => {
          this.$toast.error("Problem while loading clinic-contacts: " + err);
        });
    },
    editClinicContact() {
      ClinicContactsService.editClinicContact(this.selectedClinicContact,
        this.currentClinicContact.firstName,
        this.currentClinicContact.lastName,
        this.currentClinicContact.mail,
        this.currentClinicContact.phoneNumber,
        this.currentClinicContact.note,
        this.currentClinicContact.profilePic)
        .then(() => {
          this.$toast.success("Successfully updated clinic");
          this.showCreateOrEditClinicContactDialog = false;
          this.clinicContactEditMode = false;
          this.loadWholeClinicContacts();
        })
        .catch((err) => {
          this.$toast.error("Problem while editing clinic contact: " + err.response.data.message);
        });
    },
    openEditClinicContact(item) {
      this.profilePicKey++; // force re-rendering of profile-pic-uploader
      this.clinicContactEditMode = true;
      this.currentClinicContact = {
        firstName: item.Person.firstName,
        lastName: item.Person.lastName,
        mail: item.Mail,
        note: item.Note,
        phoneNumber: item.ClinicContactPhoneNumbers.length
          ? item.ClinicContactPhoneNumbers.map((number) => {
            return { phoneNumber: number.PhoneNumber, phoneNumberType: number.PhoneNumberType };
          })
          : [{ phoneNumber: "", phoneNumberType: "" }],
        profilePic: 1,
        profilePicSrc: item.ProfilePic
      };
      this.showCreateOrEditClinicContactDialogText = "Edit clinic contact";
      this.phoneNumberNumber = item.ClinicContactPhoneNumbers.length;
      this.showCreateOrEditClinicContactButtonFunction = this.editClinicContact;
      this.selectedClinicContact = item.ClinicContactID;
      this.showCreateOrEditClinicContactDialog = true;
    },
    openCreateClinicContact() {
      this.profilePicKey++; // force re-rendering of profile-pic-uploader
      this.clinicContactEditMode = false;
      this.currentClinicContact = {
        ...this.defaultClinicContact,
        phoneNumber: [{ phoneNumber: "", phoneNumberType: "" }]
      };
      this.showCreateOrEditClinicContactDialogText = "Add clinic contact";
      this.phoneNumberNumber = 1;
      this.showCreateOrEditClinicContactButtonFunction = this.addClinicContact;
      this.showCreateOrEditClinicContactDialog = true;
    },
    deleteClinicContact(item) {
      this.genericDialog.title = "Confirm deletion";
      this.genericDialog.text = "You're about to delete the Clinic Contact \"" + item.Person.firstName + " " + item.Person.lastName + "\". Continue?";
      this.showGenericDialog = true;
      this.genericDialog.cancelClick = this.genericDialog.defaultCancelClick;
      this.genericDialog.okClick = () => {
        this.showGenericDialog = false;
        ClinicContactsService.deleteClinicContact(item.ClinicContactID)
          .then(() => {
            this.$toast.success("Successfully deleted clinic contact " + item.Person.firstName + " " + item.Person.lastName);
            this.loadWholeClinicContacts();
          })
          .catch((err) => {
            this.$toast.error("Couldn't delete clinic contact " + item.Person.firstName + " " + item.Person.lastName);
          });
      };
    },
    openNoteDialog(item) {
      this.genericDialog.title = "Note about " + item.Person.firstName + " " + item.Person.lastName;
      this.showGenericDialog = true;
      this.genericDialog.cancelClick = null;
      this.genericDialog.text = item.Note;
    },
    addPhoneNumber() {
      this.phoneNumberNumber++;
      this.currentClinicContact.phoneNumber.push({
        phoneNumber: "",
        phoneNumberType: ""
      });
    },
    removePhoneNumber(item) {
      const foundIndex = this.currentClinicContact.phoneNumber.findIndex(
        (phoneNumber) => phoneNumber.phoneNumber === item.phoneNumber && phoneNumber.phoneNumberType === item.phoneNumberType
      );
      foundIndex > -1 && this.currentClinicContact.phoneNumber.splice(foundIndex, 1);
    },
    addClinicContact() {
      ClinicContactsService.addClinicContact(
        this.clinicId,
        this.currentClinicContact.firstName,
        this.currentClinicContact.lastName,
        this.currentClinicContact.mail,
        this.currentClinicContact.phoneNumber,
        this.currentClinicContact.note,
        this.currentClinicContact.profilePic
      )
        .then(() => {
          this.$toast.success("Successfully added contact");
          this.showCreateOrEditClinicContactDialog = false;
          this.loadWholeClinicContacts();
        })
        .catch((err) => {
          if (err.fileSizeError) {
            this.$toast.error("Error while adding contact: " + err.message);
          } else {
            this.$toast.error("Error while adding contact: " + err.response.data.message);
          }
        });
    }
  }
};
</script>

<style>
.no-padding {
  padding-left: 0;
  padding-right: 0;
}
</style>
