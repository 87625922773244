/**
 * compares 2 versions of a format 3.0.0_10, 2.9.10_123, 0.3.69_420
 *
 * @param version1 - version of given format
 * @param version2 - version of given format
 * @return {number} - negative value if version1 < version2, positive value if version2 < version1, else: 0
 */
const compareSemanticVersionWithUnderscoreSuffix = (version1, version2) => {
  let versions = { version1: version1, version2: version2 };
  for (key in versions) {
    let [prefix, suffix] = versions[key].split('_');
    const prefixParts = prefix.split('.').map(part => parseInt(part, 10))
    suffix = parseInt(suffix, 10)
    versions[key] = { prefix: prefixParts, suffix: suffix };
  };
  for (let i = 0; i < versions['version1'].prefix.length; i++) {
    if (versions['version1'].prefix[i] !== versions['version2'].prefix[i]) {
      return versions['version1'].prefix[i] - versions['version2'].prefix[i];
    }
  }
  return versions['version1'].suffix - versions['version2'].suffix;
};

module.exports = {
  compareSemanticVersionWithUnderscoreSuffix
}
