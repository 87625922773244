var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',[_c('v-row',{attrs:{"justify":"center"}},[_c('mex-sheet',{attrs:{"rounded":"","width":"50%"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-p',{attrs:{"content":("You need to authenticate yourself to be able to " + _vm.operationDescription + "."),"alignment":"center","fontSize":"overline"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateLoginForm)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","dense":"","filled":"","label":"Username","prepend-icon":"mdi-account","readonly":"","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","label":"Password","prepend-icon":"mdi-form-textbox-password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"2FA Code","rules":"required|numeric|max:6|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","label":"2 FA","prepend-icon":"mdi-two-factor-authentication","type":"text"},model:{value:(_vm.tfaToken),callback:function ($$v) {_vm.tfaToken=$$v},expression:"tfaToken"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-btn',{staticClass:"ma-5",attrs:{"content":"Authenticate","type":"submit"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-btn',{staticClass:"ma-5",attrs:{"content":"Cancel"},on:{"click":_vm.abortAuthentication}})],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }