var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expansion-sheet',{attrs:{"elevation":_vm.locationOverviewElevation,"color":"foreground","rounded":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('mex-p',{attrs:{"content":"Locations","fontSize":"h6","fontWeight":"bold-italic"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[(!_vm.hideClinicGoTo || !_vm.hideOrgaGoTo)?[_c('v-col',{attrs:{"cols":"2"}},[(!_vm.hideClinicGoTo)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goToClinicView}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-right-bold-hexagon-outline")]),_vm._v(" Clinic ")],1)]}}],null,false,77132176)},[_c('span',[_vm._v("Go to clinic view")])])],1)],1):_vm._e(),(!_vm.hideOrgaGoTo)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goToOrganizationView}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-right-bold-hexagon-outline")]),_vm._v(" Organization ")],1)]}}],null,false,3168522832)},[_c('span',[_vm._v("Go to organization view")])])],1)],1):_vm._e()],1)]:_vm._e(),_c('v-col',{attrs:{"cols":10 + 2 * Number(_vm.wideTable)}},[_c('mex-data-table',{staticClass:"ma-0",attrs:{"data":_vm.locations,"headers":_vm.getTableHeaders,"tableClass":"foreground","dense":"","footer-props":{
            'items-per-page-options': _vm.getTablePagination.rowsPerPage,
          },"hide-default-footer":"","items-per-page":_vm.getTablePagination.defaultRowsPerPage},scopedSlots:_vm._u([(_vm.readLocations)?{key:"item.goToLocation",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToLocationView(item.LocationID)}}},[_vm._v(_vm._s(item.name))])]}}:{key:"item.goToLocation",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.installationDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.installationDate ? item.installationDate.replace('Z', '').replace('T', ' ') : '-')+" ")]}},{key:"item.rentalSettings",fn:function(ref){return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.goToRentalSettings()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-playlist-plus")])],1)]}},{key:"item.featureView",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.goToFeatureView(item.LocationID)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-puzzle")])],1)]}},{key:"item.featureSettings",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.goToFeatureSettings(item.LocationID)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-puzzle-check")])],1)]}},{key:"item.locationView",fn:function(ref){
          var item = ref.item;
return [_c('mex-btn',{attrs:{"small":"","icon":"mdi-hospital-marker","iconOnly":""},on:{"click":function($event){return _vm.goToLocationView(item.LocationID)}}})]}}],null,true)})],1)],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }