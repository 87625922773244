<template>
  <v-overlay>
    <v-row justify="center">
      <mex-sheet rounded width="50%">
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p :content="`You need to authenticate yourself to be able to ${operationDescription}.`" alignment="center" fontSize="overline" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateLoginForm)">
              <v-row justify="center">
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" name="Username" rules="required">
                    <v-text-field
                      v-model="username"
                      :error-messages="errors"
                      class="mt-10"
                      color="secondary"
                      dense
                      filled
                      label="Username"
                      prepend-icon="mdi-account"
                      readonly
                      type="text"
                    />
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="Password" rules="required">
                    <v-text-field
                      v-model="password"
                      :error-messages="errors"
                      class="mt-10"
                      color="secondary"
                      label="Password"
                      prepend-icon="mdi-form-textbox-password"
                      type="password"
                    />
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="2FA Code" rules="required|numeric|max:6|min:6">
                    <v-text-field
                      v-model="tfaToken"
                      :error-messages="errors"
                      class="mt-10"
                      color="secondary"
                      label="2 FA"
                      prepend-icon="mdi-two-factor-authentication"
                      type="text"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="auto">
                  <mex-btn class="ma-5" content="Authenticate" type="submit" />
                </v-col>
                <v-col cols="auto">
                  <mex-btn class="ma-5" content="Cancel" @click="abortAuthentication" />
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-row>
      </mex-sheet>
    </v-row>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import SystemUsersService from '../../services/systemUsers.service';

export default {
  name: 'PreOperationAuthenticator',
  props: {
    operationDescription: {
      type: String,
    },
  },
  data() {
    return {
      username: '',
    };
  },
  created() {
    SystemUsersService.getOwnSystemUserData(this.getUser).then((response) => {
      this.username = response.data.username;
    });
  },
  computed: {
    ...mapGetters('sysAuth', ['isLoggedIn', 'getUser']),
  },
  methods: {
    validateLoginForm() {
      this.authenticateSystemUser();
      this.$store.commit('sysAuth/lockSession');
    },
    authenticateSystemUser() {
      this.loginLoading = true;
      SystemUsersService.systemUserAuthenticate(this.username, this.password, this.tfaToken)
        .then((response) => {
          this.successfulLogin(response.data);
        })
        .catch((err) => {
          this.failedLogin(err);
        });
    },

    successfulLogin() {
      this.$emit('successful-authentication');
      this.$store.commit('sysAuth/unlockSession');
    },
    failedLogin(authError) {
      if (authError.response && authError.response.status === 401) {
        this.$toast.error('Authentication failed');
      } else if (authError.response && authError.response.status === 404) {
        this.$toast.error(authError.response.data);
      } else if (authError.response && authError.response.status === 403) {
        this.$toast.warning(authError.response.data);
      } else {
        this.$toast.error(`System Error. Authentication failed: ${authError}`);
      }
    },
    abortAuthentication() {
      this.$store.commit('sysAuth/unlockSession');
      this.$emit('abort');
    },
  },
};
</script>

<style></style>
