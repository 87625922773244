import httpClient from "../httpClient/httpClient";

const DbUpdateLogsService = {
  getDbUpdateLogsOverview(filterData) {
    return httpClient.get("api/get-db-update-logs", {
      params: {
        filterData
      }
    });
  },
  getVersions() {
    return httpClient.get("api/get-db-update-log-versions");
  },
  getDbUpdateLogsOfClinic(clinicID) {
    return httpClient.get("api/get-db-update-logs", {
      params: {
        clinicID
      }
    });
  },
};

export default DbUpdateLogsService;
