import httpClient from "../httpClient/httpClient";

const ClinicContactsService = {
  addClinicContact(
    clinicID,
    firstName,
    lastName,
    mail,
    phoneNumber,
    note,
    profilePic,
    onUploadProgress
  ) {
    // 50MB is maximum request size, leave 1 MB for request-overhead
    const maxRequestSize = 49 * 1024 * 1024;

    if (profilePic.size > maxRequestSize) {
      return Promise.reject({ fileSizeError: true, message: 'Profile-Pic is larger than maximum file-size of 49 MB'});
    }

    const formData = new FormData();
    formData.append("file", profilePic);
    return httpClient.post("api/add-clinic-contact", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        clinicID,
        firstName,
        lastName,
        mail,
        phoneNumber,
        note,
      },
      onUploadProgress
    });
  },

  getClinicContacts(clinicID) {
    return httpClient.get("api/get-clinic-contacts", {
      params: {
        clinicID
      }
    });
  },

  getClinicContactProfilePic(clinicContactID) {
    return httpClient.get("api/get-clinic-contact-profile-pic", {
      params: {
        clinicContactID
      }
    });
  },

  editClinicContact(
    clinicContactID,
    firstName,
    lastName,
    Mail,
    phoneNumber,
    Note,
    profilePic,
    onUploadProgress
  ) {
    const route = "api/edit-clinic-contact";
    if (![0, 1].includes(profilePic)) {
      const formData = new FormData();
      formData.append("file", profilePic);
      return httpClient.post(route, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          clinicContactID,
          firstName,
          lastName,
          Mail,
          phoneNumber,
          Note,
        },
        onUploadProgress
      });
    } else {
      return httpClient.post(route, {}, {
        params: {
          clinicContactID,
          firstName,
          lastName,
          Mail,
          phoneNumber,
          Note,
          profilePic,
        }
      });
    }
  },

  deleteClinicContact(clinicContactID) {
    return httpClient.post("api/delete-clinic-contact", {
      clinicContactID,
    });
  },

};

export default ClinicContactsService;
