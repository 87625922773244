import httpClient from "../httpClient/httpClient";

const ClinicSettingsService = {
  getClinicSettingsOfClinic(clinicID) {
    return httpClient.get("api/get-clinic-settings-of-clinic", {
      params: {
        clinicID
      }
    });
  },
  updateClinicSettingData(clinicID, periodToDeactivation) {
    return httpClient.post("api/update-clinic-settings-data", {
      clinicID,
      periodToDeactivation,
    });
  }
};

export default ClinicSettingsService;
