import httpClient from '../httpClient/httpClient';

const RegisterTypesService = {
  getRegisterTypeNames() {
    return httpClient.get('api/get-register-type-names');
  },
  checkIfHfea(registerTypeID) {
    return httpClient.get('api/check-if-register-type-is-hfea', {
      params: {
        registerTypeID,
      },
    });
  }
};

export default RegisterTypesService;
