<template>
  <v-overlay v-if="showSaveRequest" style="z-index: 900">
    <mex-sheet color="waring" rounded>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-p content="SAVE CHANGES?" fontSize="title" fontWeight="bold" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-p alignment="center" content="Please leave the edit mode first!" fontSize="overline" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-p content="There may be some unsaved changes. Save or discard them." fontSize="overline" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn content="Okay" @click="$emit('closeSaveRequest')" />
        </v-col>
      </v-row>
    </mex-sheet>
  </v-overlay>
</template>

<script>
export default {
  name: 'SaveRequest',
  props: {
    showSaveRequest: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
